@import './variable.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ol {
    padding-left: 20px;
    li {
        padding-left: 10px;
    }
}

html,
body {
    font-family: $FONT_PRIMARY;
    font-size: $FONTSIZE_BODY;
    font-weight: $FONTWEIGHT_NORMAL;
}

.MuiFormLabel-root.MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1);
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(15px, 1.5px) scale(1) !important;
}
legend[class^='PrivateNotchedOutline-legendLabelled-'] {
    font-size: 1em;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-decoration: none;
}

.hide {
    display: none !important;
}

.w100 {
    width: 100% !important;
}

.wrapper {
    position: relative;
    margin: 0 auto;
}

.mainWrapper {
    position: relative;
}

.contentWrapper {
    width: calc(100% - 260px);
    margin-left: 260px;

    .content {
        width: 100%;
        padding: 30px 10px;
    }
}

.MuiGrid-item {
    padding: 10px;

    &.p0 {
        padding: 0;
    }

    &.px0 {
        padding: 10px 0;
    }
}

label {
    &.MuiFormLabel-root,
    .MuiInputLabel-formControl {
        &.MuiFormLabel-root {
            color: $COLOR_GREEN;
            font-weight: $FONTWEIGHT_MEDIUM;
            font-size: $FONTSIZE_BODY;
        }
    }
}

// .MuiButton-outlined {
//     border: 1px solid $COLOR_BLUE;
//     color: $COLOR_BLUE;
// }

.MuiButton-root {
    &.btn {
        font-weight: $FONTWEIGHT_BOLD;
        &:disabled {
            opacity: 0.5;
        }
    }

    &.MuiButton-outlined {
        border: 1px solid $COLOR_BLUE;
        color: $COLOR_BLUE;
    }

    &.MuiButton-contained {
        background-color: $COLOR_SKYBLUE;
        color: $COLOR_WHITE;

        &:hover {
            background-color: $COLOR_BLUE;
            color: $COLOR_WHITE;
        }

        &:disabled {
            background-color: $COLOR_SKYBLUE;
            color: $COLOR_WHITE;
            opacity: 0.5;

            &:hover {
                background-color: $COLOR_SKYBLUE;
            }
        }
    }

    &.btnSkyBlue {
        background-color: $COLOR_SKYBLUE;
        color: $COLOR_WHITE;
    }

    &.btnDefault {
        background-color: $COLOR_LIGHTGREY;
        color: $COLOR_BLACK;
    }

    &.btnCancel {
        background-color: $COLOR_DANGERRED;
        border: 1px solid $COLOR_DANGERRED;
        color: $COLOR_WHITE;

        &:hover {
            background-color: $COLOR_CHERRYRED;
            border: 1px solid $COLOR_CHERRYRED;
        }

        &:disabled {
            color: $COLOR_WHITE;

            &:hover {
                background-color: $COLOR_CHERRYRED;
                border: 1px solid $COLOR_CHERRYRED;
            }
        }
    }

    &.btnOutlineSkyBlue {
        border-color: $COLOR_SKYBLUE;
        color: $COLOR_SKYBLUE;
    }

    &.tableActionBtn {
        font-size: $FONTSIZE_16;
        font-weight: $FONTWEIGHT_MEDIUM;
    }

    &.left {
        margin-right: 30px;
    }

    &.left10 {
        margin-right: 10px;
    }
}

.MuiButton-root .MuiTypography-root {
    line-height: 1 !important;
}

.txtBlue {
    color: $COLOR_BLUE;
}

.animationDisabled {
    animation-duration: 0s !important;
}
