@import 'common/styles/variable.scss';

.loadingWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        width: 50px;

        img {
            width: 100%;
        }
    }

    .loading {
        width: 100px;
        stroke: $COLOR_NAVIGATE;

        img {
            width: 100%;
        }
    }
}
