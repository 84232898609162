@font-face {
    font-family: 'LibreFranklin';
    font-weight: 400;
    src: local('LibreFranklin'),
        url('../assets/fonts/LibreFranklin/LibreFranklin-Regular.ttf')
            format('truetype');
}

@font-face {
    font-family: 'LibreFranklin';
    font-weight: 500;
    src: local('LibreFranklin'),
        url('../assets/fonts/LibreFranklin/LibreFranklin-Medium.ttf')
            format('truetype');
}

@font-face {
    font-family: 'LibreFranklin';
    font-weight: 600;
    src: local('LibreFranklin'),
        url('../assets/fonts/LibreFranklin/LibreFranklin-SemiBold.ttf')
            format('truetype');
}

@font-face {
    font-family: 'LibreFranklin';
    font-weight: 700;
    src: local('LibreFranklin'),
        url('../assets/fonts/LibreFranklin/LibreFranklin-Bold.ttf')
            format('truetype');
}

@font-face {
    font-family: 'LibreFranklin';
    font-weight: 800;
    src: local('LibreFranklin'),
        url('../assets/fonts/LibreFranklin/LibreFranklin-ExtraBold.ttf')
            format('truetype');
}

@font-face {
    font-family: 'LibreFranklin';
    font-weight: 900;
    src: local('LibreFranklin'),
        url('../assets/fonts/LibreFranklin/LibreFranklin-Black.ttf')
            format('truetype');
}

@font-face {
    font-family: 'NotoSansCJKsc';
    font-weight: 400;
    src: local('NotoSansCJKsc'),
        url('../assets/fonts/NotoSansCJKsc/NotoSansCJKsc-Light.otf')
            format('opentype');
}

@font-face {
    font-family: 'NotoSansCJKsc';
    font-weight: 500;
    src: local('NotoSansCJKsc'),
        url('../assets/fonts/NotoSansCJKsc/NotoSansCJKsc-DemiLight.otf')
            format('opentype');
}

@font-face {
    font-family: 'NotoSansCJKsc';
    font-weight: 600;
    src: local('NotoSansCJKsc'),
        url('../assets/fonts/NotoSansCJKsc/NotoSansCJKsc-Regular.otf')
            format('opentype');
}

@font-face {
    font-family: 'NotoSansCJKsc';
    font-weight: 700;
    src: local('NotoSansCJKsc'),
        url('../assets/fonts/NotoSansCJKsc/NotoSansCJKsc-Medium.otf')
            format('opentype');
}

@font-face {
    font-family: 'NotoSansCJKsc';
    font-weight: 800;
    src: local('NotoSansCJKsc'),
        url('../assets/fonts/NotoSansCJKsc/NotoSansCJKsc-Bold.otf')
            format('opentype');
}

@font-face {
    font-family: 'NotoSansCJKsc';
    font-weight: 900;
    src: local('NotoSansCJKsc'),
        url('../assets/fonts/NotoSansCJKsc/NotoSansCJKsc-Black.otf')
            format('opentype');
}
