// @import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './fonts.scss';

@import '../../theme.module.scss';

// Colors
$COLOR_GREEN: $COLOR_NAVIGATE;
$COLOR_LIGHTGREEN: #e5eff0;
$COLOR_MINTGREEN: #52d869;
$COLOR_SACRAMENTOGREEN: #002f33;

$COLOR_BLUE: $COLOR_SECONDARY;
$COLOR_AZURE: $COLOR_ACTIVE;
$COLOR_SKYBLUE: $COLOR_PRIMARY;
$COLOR_BACKGROUND_LIGHT: $COLOR_BACKGROUND_LIGHT;

$COLOR_DANGERRED: #e00000;
$COLOR_CHERRYRED: #c40000;
$COLOR_DARKRED: #ad1111;

$COLOR_YELLOW: #ffb200;
$COLOR_TUSCANYYELLOW: #f2a100;

$COLOR_GREY: #454b52;
$COLOR_LIGHTGREY: #f6f6f6;
$COLOR_SMOKEGREY: #ededed;
$COLOR_STEELGREY: #656565;
$COLOR_CHARCOAL: #333333;

$COLOR_SILVER: #c0c0c0;

$COLOR_BLACK: #000000;
$COLOR_WHITE: #ffffff;

// Font
$FONT_PRIMARY: 'LibreFranklin', 'NotoSansCJKsc';
$FONT_SECONDARY: 'Roboto Slab', sans-serif;

// Font Size
$FONTSIZE_BODY: 14px;
$FONTSIZE_16: 16px;
$FONTSIZE_17: 17px;
$FONTSIZE_18: 18px;
$FONTSIZE_20: 20px;
$FONTSIZE_24: 24px;
$FONTSIZE_30: 30px;
$FONTSIZE_40: 40px;

// Font weight
$FONTWEIGHT_NORMAL: 400;
$FONTWEIGHT_MEDIUM: 500;
$FONTWEIGHT_SEMIBOLD: 600;
$FONTWEIGHT_BOLD: 700;
$FONTWEIGHT_EXTRABOLD: 800;
$FONTWEIGHT_BLACK: 900;
