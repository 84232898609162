$COLOR_PRIMARY: #00A3E0; 
$COLOR_SECONDARY: #006ac6;

$COLOR_ACTIVE: #10069f;
$COLOR_NAVIGATE: #006269;

$COLOR_BACKGROUND_LIGHT: #e5f5fb;

:export {
    COLOR_PRIMARY: $COLOR_PRIMARY;
    COLOR_SECONDARY: $COLOR_SECONDARY;
    COLOR_ACTIVE: $COLOR_ACTIVE;
    COLOR_NAVIGATE: $COLOR_NAVIGATE;
    COLOR_BACKGROUND_LIGHT: $COLOR_BACKGROUND_LIGHT;
}